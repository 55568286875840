import React from 'react';
import Layout from '../components/Layout';
import { Container, Heading, Text } from '../components/UI';

const NotFoundPage = () => (
  <Layout>
    <Container textAlign="center" my="subsection">
      <Heading>(404) NOT FOUND</Heading>
      <Text fontSize="xl">Siden du prøver at tilgå eksisterer ikke.</Text>
    </Container>
  </Layout>
)

export default NotFoundPage
